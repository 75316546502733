import { Links, Meta, Outlet, Scripts, ScrollRestoration } from '@remix-run/react';
import type { LinksFunction } from '@remix-run/node';
import { MantineProvider } from '@mantine/core';

import '@mantine/core/styles.css';
import styles from './tailwind.css?url';
import { useState } from 'react';
import { trpc } from './api/trpc';
import { httpBatchLink } from '@trpc/client';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useClearSearchFilters } from './hooks/useClearSearchFilters';
import { Toaster } from '@/components/ui/sonner';

export const links: LinksFunction = () => [{ rel: 'stylesheet', href: styles }];

export const loader = () => {
  return {};
};

function Document({ env, children }: { env?: Record<string, string>; children: React.ReactNode }) {
  return (
    <html lang="en">
      <head>
        <Meta />
        <Links />
      </head>
      <body>
        <script
          // biome-ignore lint/security/noDangerouslySetInnerHtml: only way to load on global scope
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(env)}`,
          }}
        />
        {children}
        <Toaster />
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

export function Layout({ children }: { children: React.ReactNode }) {
  useClearSearchFilters();

  const [trpcClient] = useState(() =>
    trpc.createClient({
      links: [
        httpBatchLink({
          url: '/api',
          fetch(url, options) {
            return fetch(url, {
              ...options,
              credentials: 'include',
            });
          },
        }),
      ],
    })
  );

  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            // With SSR, we usually want to set some default staleTime
            // above 0 to avoid refetching immediately on the client
            staleTime: 1000 * 60 * 5,
            refetchOnWindowFocus: true,
            refetchOnReconnect: true,
          },
        },
      })
  );

  return (
    <Document>
      <MantineProvider>
        <trpc.Provider client={trpcClient} queryClient={queryClient}>
          <QueryClientProvider client={queryClient}>
            <div className="flex flex-1 flex-col sm:gap-4 sm:py-4 print:gap-0 print:pl-0">{children}</div>
          </QueryClientProvider>
        </trpc.Provider>
      </MantineProvider>
    </Document>
  );
}

export default function App() {
  return <Outlet />;
}
